<template>
  <section :class="$style.faqs">
    <div :class="$style.wrapper">
      <el-button
        type="primary"
        :class="$style.button"
        @click="$router.push('faqs/create')"
      >
        Создать
      </el-button>
    </div>

    <div v-for="(category, index) in faqCategories" :key="category.id">
      <div :class="$style.category" v-if="category.faqs">
        <div :class="$style.responsive">
          <h3>
            {{ category.name }}
          </h3>
          <table :class="$style.tabel">
            <thead :class="$style.header">
              <tr :class="$style.row">
                <th :class="[$style.draggable, $style.nograb]"></th>
                <th :class="$style.orderfield">№ п/п</th>
                <th :class="$style.name">Название FAQ</th>
              </tr>
            </thead>
            <Container
              tag="tbody"
              :class="$style.body"
              @drop=";(draggedIndex = index), onDrop($event)"
              @drag-start="drag = false"
              @drag-end="drag = true"
            >
              <Draggable
                tag="tr"
                :class="$style.row"
                v-for="item in category.faqs"
                :key="item.id"
              >
                <td :class="$style.draggable">
                  <Icon name="draggable" :class="$style.icon" />
                </td>
                <td :class="$style.orderfield">
                  {{ item.orderField }}
                </td>
                <td :class="$style.name">
                  {{ item.question }}
                </td>
                <td>
                  <ActionButtons
                    :class="$style.buttons"
                    :edit-link="'faqs/' + String(item.id)"
                    @delete="remove(item.id)"
                  />
                </td>
              </Draggable>
            </Container>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import delivery from '@/delivery'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Icon from '@/components/atoms/Icon'

export default {
  components: { ActionButtons, Icon },
  data() {
    return {
      faqCategories: [],
      draggedIndex: null,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true,
      })
      const { value, error } =
        await delivery.ContentServiceCore.FAQCategoriesActions.getList({
          withPagination: 'false',
        })
      loading.close()

      if (error) return

      this.faqCategories = value.data
    },

    async remove(id) {
      const isConfirm = confirm('Вы точно хотите удалить FAQ?')
      if (!isConfirm) {
        return
      }
      const res = await delivery.ContentServiceCore.FAQsActions.delete(id)
      if (!res.error) {
        this.$message({
          message: 'FAQ успешно удалён',
          type: 'success',
        })
        this.getList()
      }
    },

    async save(list) {
      const loading = this.$loading({
        lock: true,
      })

      let updateList = list.filter((item, index) => {
        if (item.orderField != index + 1) {
          item.orderField = index + 1
          return item
        }
      })

      const res = await delivery.ContentServiceCore.FAQsActions.update(
        updateList,
      )
      loading.close()

      if (res.error) alert(res.error)
    },

    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    onDrop(dropResult) {
      document.body.classList.remove('smooth-dnd-no-user-select')
      document.body.classList.remove('smooth-dnd-disable-touch-action')

      this.faqCategories[this.draggedIndex].faqs = this.applyDrag(
        this.faqCategories[this.draggedIndex].faqs,
        dropResult,
      )

      this.save(this.faqCategories[this.draggedIndex].faqs)
    },
  },
}
</script>

<style lang="scss" module>
.faqs {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
    .button {
      margin: 1rem 0 1.5rem;
    }
  }
  .category {
    padding: 2rem;
    .responsive {
      border-radius: 0.25rem;
      @include tabelShadow;
      width: 100%;
      overflow-x: auto;
      .tabel {
        width: 100%;
        table-layout: auto;
        .header {
          th {
            color: $tabel-th-color;
            font-weight: 700;
            text-align: left;
          }
        }
        .row {
          display: block;
          padding: 0.5rem;
          border-bottom: 0.0625rem solid $tabel-border;
          &:nth-child(odd) {
            background-color: $bgc-tabel-row;
          }
          .draggable {
            min-width: 5rem;
            cursor: move;
            &.nograb {
              cursor: default;
            }
            .icon {
              width: 1rem;
              height: 1rem;
            }
          }
          .name {
            width: 90%;
            text-align: left;
          }
          .orderfield {
            min-width: 10rem;
          }
        }
      }
    }
  }
}
</style>
